.navbar {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 10;

    h1 {
        display: none;
    }

    >img {
        width: 12rem;
        border: 0;
        margin: 1rem auto;
        transition: all 350ms ease-in-out;
        border-bottom: 1px solid transparent;
    }

    .rounded-icon {
        display: none;
    }

    >img:hover {
        border-color: #ffff;
    }

    .navbar-items {
        display: flex;
        flex: 1 0 100%;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        nav {
            box-sizing: border-box;

            >a {
                display: inline-block;
                text-decoration: none;
                margin: 0 1rem;
                padding: 0;
                box-sizing: border-box;
                font-size: 1em;
            }
        }

        .theme-switcher {
            box-sizing: border-box;
            background: transparent;
            display: flex;
            margin: 0 1rem;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 0;
                width: 2rem;
                aspect-ratio: 1 / 1;
                box-sizing: border-box;
                padding: 0;
                cursor: pointer;
            }

            >.dark {
                background-color: #202020;
            }

            >.light {
                background-color: #afafaf;
            }

            >.dark:hover,
            >.light:hover {
                opacity: .85;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .navbar {
        .text-icon {
            display: none;
        }

        .rounded-icon {
            display: inline-block;
            width: 4rem;
        }

    }

    .theme-switcher {
        margin: 0;
    }
}