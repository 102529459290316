@keyframes projects-h1 {
    from {width: 0;}
    to {width: 12em;}
}

.projects {    
    main {
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        align-items: center;

        h1 {
            margin-top: 10rem;
            width: 12em;
            font-size: 180%;
            animation:
                projects-h1 600ms steps(23) 400ms 1 normal both,
                blinkTextCursor 500ms steps(15) infinite normal;
        }

        .projects-list {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            >figure {
                display: inline-block;
                width: calc(25% - 1rem);
                margin: 1rem;
                opacity: .5;
                cursor: pointer;
                transition: all 250ms ease-in-out;
                border: 2px solid transparent;
                border-radius: 12px;
                padding: 1rem;
                box-sizing: border-box;

                >img {
                    width: 100%;
                    margin: 0;
                    aspect-ratio: 2 / 1;
                }

                >figcaption {
                    margin: .5rem 0;
                    text-align: center;
                    word-wrap: break-word;
                    font-size: 1rem;

                    strong {
                        font-size: 1.4rem;
                    }
                }
            }

            >figure:hover {
                opacity: 1;
                border-color: #ffff;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .projects {
        main {
            .projects-list {
                figure {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}