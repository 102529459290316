@font-face {
    font-family: Poppins;
    src: url("/public/fonts/Poppins-Regular.ttf") format('truetype');;
}

@keyframes blinkTextCursor {
    from {
        border-right-color: #ffffffbf;
    }

    to {
        border-right-color: transparent;
    }
}

html, body {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: Poppins, Roboto, sans-serif;
}

img, p, h1, h2, h3, h4, h5, h6, p {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
}

.home, .sobre, .projects {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
}

.anim-text {
    position: relative;
    margin: 0 auto;
    text-align: center;
    border-right: 2px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

a:hover {
    opacity: 0.85;
}