.footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 0;
    padding: 2rem 0;
    box-sizing: border-box;
    border-top: 1px solid #585858;
    
    > .footer-left {
        text-align: start;
        > * {
            display: block;
            text-decoration: none;
            margin: .5rem 0;
        }
    }

    .full-name {
        text-align: center;

        > * {
            margin: 0;
        }
    }

    .short-name {
        display: none;
    }

    > .footer-right {
        display: flex;
        width: 6rem;
        flex-wrap: wrap;
        text-align: center;
        box-sizing: border-box;

        > a {
            width: 2.5rem;
            display: inline-block;
            margin: .25rem;
            font-size: 120%;
            padding: 0;
            aspect-ratio: 1 / 1;
            text-decoration: none;
            box-sizing: border-box;
            border-radius: 50%;

            > i {
                margin: 0;
                padding: 0;
                line-height: 2.5rem;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .footer {
        .full-name {
            display: none;
        }

        .short-name {
            display: inline-block;
        }
    }
}

@media only screen and (max-width: 450px) {
    .footer {
        > .footer-left {
            display: none;
        }
    }
}