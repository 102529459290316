@keyframes about-h1 {
    from {width: 0;}
    to {width: 10em;}
}

.about {
    main {
        display: inline-block;
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        .content {
            width: 50%;
            margin: 0 auto;
            margin-top: 10rem;
            box-sizing: border-box;
    
            h1 {
                width: 10em;
                font-size: 180%;
                animation:
                    about-h1 600ms steps(19) 400ms 1 normal both,
                    blinkTextCursor 500ms steps(15) infinite normal;
            }

            p {
                text-wrap: wrap;
                text-align: center;
                text-indent: 12px;
                font-size: 1.2em;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .about {
        main {
            .content {
                padding: 0 2rem;
                width: 100%;
            }
        }
    }
}