@keyframes home-h1 {
    from {width: 0;}
    to {width: 5.5em;}
}

@keyframes description {
    from {width: 0;}
    to {width: 21.5em;}
}

.home {
    main {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;

        .contact {
            position: absolute;
            bottom: 0;
            padding: 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            box-sizing: border-box;

            >div {
                margin: .25rem 0;
                padding: 0;

                h2 {
                    margin: 0;
                }

                i {
                    font-size: 1.8em;
                    vertical-align: middle;
                }
            }

            .phones {
                >div {
                    margin: 0;
                }
            }

            strong {
                display: inline-block;
                margin: 0 1rem;
                border-bottom: 1px solid transparent;
                cursor: pointer;
                transition: all 250ms ease-in-out;
            }

            strong:hover {
                border-color: #ffff;
            }
        }

        > .text-box {
            width: 100%;
    
            h1 {
                font-size: 250%;
                width: 9em;
                animation:
                    home-h1 550ms steps(10) 500ms 1 normal both,
                    blinkTextCursor 420ms steps(8) 3 normal forwards;
            }
    
            h2 {
                width: 15em;
                font-size: 180%;
                animation:
                    description 1.5s steps(37) 1.3s 1 normal both,
                    blinkTextCursor 500ms steps(15) 1.2s 4 normal forwards;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .home {
        main {
            .text-box {
                h2 {
                    display: none;
                }
            
                .contact {
                    justify-content: center;

                    h2 {
                        display: none;
                    }
                }
            }
        }
    }
}