.not-found {
    main {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
    }
}